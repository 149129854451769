import cn from "classnames"
import React, { useState } from "react"
import { Input, Label } from "reactstrap"
import "./CheckboxWithSpinner.scss"
import { SpinnerContainer } from "../../../loading/SpinnerContainer"

interface ICheckboxWithSpinner {
    checked: boolean
    handleChange: (checked: boolean) => void | Promise<void>
    name?: string
    label?: string
    disabled?: boolean
    checkboxHidden?: boolean
}

export const CheckboxWithSpinner = ({ checked, handleChange, name, label, disabled, checkboxHidden }: ICheckboxWithSpinner) => {
    const [isChanging, setIsChanging] = useState(false)

    const checkbox = (
        <>
            <Input
                name={name}
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={async () => {
                    setIsChanging(true)
                    try {
                        await handleChange(!checked)
                    } catch {
                        // too make sure changing is set back to false and we stop showing the spinner
                    }
                    setIsChanging(false)
                }}
            />
        </>
    )

    return (
        <SpinnerContainer showSpinner={isChanging} className="checkbox-spinner">
            {label ? (
                <Label className={cn({ "checkbox-label__indented": !checkboxHidden })}>
                    {!checkboxHidden && checkbox}
                    {label}
                </Label>
            ) : (
                checkbox
            )}
        </SpinnerContainer>
    )
}
